@use "src/sedestral/sass/mixins/positioning";
@use "src/sedestral/sass/mixins/texts";
@use "src/sedestral/sass/extends/boxs";

.pageLogin {
  height: 100%;
  width: 100%;

  @include positioning.screen-fixed;
  @include positioning.flex-globally;

  .container {
    z-index: 99999;
    width: 390px;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;

    .loginContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      margin-top: 20px;
      gap: 25px;
      height: fit-content;

      @include positioning.flex-globally;

      .header {
        height: 34px;
        position: relative;
        width: 100%;

        @include positioning.flex-globally;

        .logo {
          height: 34px;
          width: 34px;
          float: left;
        }

        .name {
          font-size: 26px;
          margin-left: 12px;
          float: left;

          @include texts.font("semi-bold");
        }
      }


      .component {
        height: fit-content;
        width: calc(100% - 40px);
        padding: 20px;
        border-radius: 13px;
        position: relative;

        @include boxs.sur;
      }

      .copyrightContainer {
        width: 100%;
        position: relative;
        margin-bottom: 20px;
      }
    }
  }

  @media screen and (max-width: 480px) {
    div[globalBg] {
      display: none;
    }
    .container {
      width: 100% !important;

      .loginContainer {
        .component {
          box-shadow: unset !important;
          border-radius: unset !important;
          margin-left: unset !important;
          width: calc(100% - 59px) !important;
          padding: 30px !important;
        }
      }
    }
  }

  @media screen and (max-height: 600px) {
    .container {
      align-items: normal;
    }
  }
}
