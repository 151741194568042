@use "src/sedestral/sass/mixins/positioning";
@use "src/sedestral/sass/extends/inputs";
@use "src/sedestral/sass/mixins/outline";
@use "src/sedestral/sass/icons/icons";
@use "../../../../../sedestral/sass/mixins/texts";
@use "src/sedestral/sass/extends/buttons";
@use "src/sedestral/sass/extends/boxs";

.componentLogin {
  height: 100%;
  width: 100%;

  .bannerContainer {
    @include boxs.sur;

    position: relative;
    box-shadow: 0 0 0 1px rgb(0 0 0 / 3%), 0 0 8px 0 rgb(0 0 0 / 9%);
    height: 52px;
    margin-bottom: 15px;
    width: 100%;
    border-radius: 12px;

    svg {
      width: 22px;
      height: 22px;
    }
  }

  .socialsContainer {
    visibility: visible;
    margin-top: 15px;
  }

  .withMail {
    height: 46px;
    font-size: 15px;
    width: 100%;
    position: relative;

    @include positioning.flex-globally;
    @include texts.font("semi-bold");
  }

  .form {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 13px;
    margin: 0;

    .inputContainer {
      height: 42px;
      position: relative;
    }

    .postButton {
      @include buttons.grey;
      @include outline.blue-hover(4);
      @include positioning.flex-globally;
      @include texts.font("bold");

      height: 52px;
      font-size: 14px;
      border-radius: 14px;
      background: var(--grey-sur);
      position: relative;
      width: 100%;
      color: var(--black);
    }
  }

  .menu {
    font-size: 11px;
    margin-top: 15px;
    width: 100%;

    @include positioning.clear;

    .text {
      @include buttons.round;
      @include texts.ellipsis(1);
      @include texts.font("semi-bold");

      padding: 8px 10px;
      border-radius: 20px;
      width: fit-content;
      cursor: pointer;

      &.left {
        text-align: left;
        float: left;
        margin-left: -5px;
      }

      &.right {
        text-align: right;
        float: right;
        margin-right: -5px;
      }
    }

    .signup {
      visibility: visible;
    }

    .lost {
      visibility: visible;
    }
  }
}